import { render, staticRenderFns } from "./App.vue?vue&type=template&id=8c1f2022&scoped=true&"
import script from "./App.vue?vue&type=script&lang=js&"
export * from "./App.vue?vue&type=script&lang=js&"
import style0 from "./App.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./App.vue?vue&type=style&index=1&id=8c1f2022&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../.pnp/externals/pnp-12b91bfd89d82152724093e32982d62081f84c72/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8c1f2022",
  null
  
)

export default component.exports