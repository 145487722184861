import { render, staticRenderFns } from "./RouteList.vue?vue&type=template&id=46be41f6&scoped=true&"
import script from "./RouteList.vue?vue&type=script&lang=js&"
export * from "./RouteList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../.pnp/externals/pnp-12b91bfd89d82152724093e32982d62081f84c72/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "46be41f6",
  null
  
)

export default component.exports