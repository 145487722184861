<template>
  <div class="home">
    <HomeHeader />

    <div class="content" v-if="content.de">
      <div class="button-thisyear" :class="{ active: thisyear===1 }" @click="thisyear=1">
        <span>{{ firstPart(content.de.buttonleiste.thisyear) }}</span> | {{ lastPart(content.de.buttonleiste.thisyear) }}
      </div>
      <div class="button-nextyear" :class="{ active: thisyear===0 }" @click="thisyear=0">
        <span>{{ firstPart(content.de.buttonleiste.nextyear) }}</span> | {{ lastPart(content.de.buttonleiste.nextyear) }}
      </div>

      <div v-if="thisyear===1" class="desc">
        <div v-if="language==='de' && content.de" v-html="content.de.text.thisyear" />
        <div v-if="language==='pl' && content.pl" v-html="content.pl.text.thisyear" />
        <div v-if="language==='cz' && content.cz" v-html="content.cz.text.thisyear" />

        <RouteList :routen="BPRoutesComputed" />

        <Video v-if="true" :title="'Das ist ein Testtitel'" :videoid="'abc'" :titlecolor="'#ffffff'"/>

      </div>

      <div v-if="thisyear===0" class="desc">
        <div v-if="language==='de' && content.de" v-html="content.de.text.nextyear" />
        <div v-if="language==='pl' && content.pl" v-html="content.pl.text.nextyear" />
        <div v-if="language==='cz' && content.cz" v-html="content.cz.text.nextyear" />
      </div>
    </div>
  </div>
</template>

<script type="text/babel">
import HomeHeader from '../components/HomeHeader.vue'
import RouteList from '../components/RouteList.vue'
import Video from '../components/Video.vue'

export default {
  name: 'HomeView',

  components: {
    HomeHeader, RouteList,Video
  },

  data: function () {
    return {
      thisyear: 1,
    }
  },
  computed: {
    BPRoutesComputed: function () {
      return this.$root.BPRoutes
    },
    language: function () {
      return this.$root.$i18n.locale
    },
    content: function () {
      return this.$root.content
    }
  },

  methods: {
    firstPart(thestring) {
      if(!thestring) return;
      return thestring.split('|')[0]
    },
    lastPart(thestring) {
      if(!thestring) return;
      return thestring.split('|')[1]
    },
  },
}

</script>



<style scoped lang="scss">
  .home {
    background-color: #467b37;

    .content {
      max-width: 1024px;
      margin: 0 auto;
      padding: 40px 80px;
      box-sizing: border-box;
      text-align: center;

      @media screen and (max-width: 768px) {
        padding: 20px 30px;
      }

      .button-thisyear,
      .button-nextyear {
        display: inline-block;
        padding: 20px;
        box-sizing: border-box;
        border: 2px solid white;
        border-radius: 25px;
        cursor: pointer;
        font-size: 20px;

        @media screen and (max-width: 768px) {
          font-size: 18px;
          padding: 15px;
        }
        @media screen and (max-width: 480px) {
          font-size: 16px;
          padding: 10px;
        }
        @media screen and (max-width: 360px) {
          font-size: 14px;
          padding: 10px;
        }

        span {
          font-weight: bold;
        }

        &.active {
          background-color: white;
          color: #467b37;
        }
      }
      .button-thisyear {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
      .button-nextyear {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }

      .desc {
        margin-top: 40px;
        text-align: left;
        z-index: 99;
        position: relative;

        @media screen and (max-width: 480px) {
          margin-top: 30px;

        }

      }

    }
  }
</style>

