import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

const messages = {
  'de': {
    title: 'Brückenpark GörlitzZgorzelec',
    welcomeMsg: 'Welcome  ',

  },
  'pl': {
    title: 'Park Mostow Zgorzelec',
    welcomeMsg: 'Bienvenido    ',

  },
}

const i18n = new VueI18n({
  locale: 'de', // set locale
  fallbackLocale: 'pl', // set fallback locale
  messages, // set locale messages
})

export default i18n