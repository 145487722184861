import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'

Vue.use(Router)

export default new Router({
    routes: [
        {
            path: '/',
            name: 'home',
            component: Home
        },

        {
            path: '/ds',
            name: 'Datenschutz',

            component: () => import(/* webpackChunkName: "ds" */ './views/Datenschutz.vue')
        }, {
            path: '/about',
            name: 'about',

            component: () => import(/* webpackChunkName: "about" */ './views/About.vue')
        },
        {
            path: '/route/:routeId',
            name: 'route',
            props: true,
            component: () => import(/* webpackChunkName: "route" */ './views/route.vue')
        },
        {
            path: '/route/waypoint/:waypointId',
            name: 'waypoint',
            props: true,
            component: () => import(/* webpackChunkName: "waypoint" */ './views/waypoint.vue')
        }
    ],
    scrollBehavior(to, from, savedPosition) {
        return {x: 0, y: 0}
    }
})
