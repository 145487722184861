<template>
  <div class="routelist">
    <ul class="routes">
      <li v-for="route in routen">
        <table>
          <tr>
            <td><img src="./../assets/picto-pfeil-link.svg" /></td>
            <td>
              <router-link :to="{ name: 'route', params: { routeId: route.id }}">
                <template v-if="language=='de'">
                  {{ route.de.Titel }}
                </template>
                <template v-if="language=='pl'">
                  {{ route.pl.Titel }}
                </template>
                <template v-if="language=='cz'">
                  {{ route.cz.Titel }}
                </template>
              </router-link>
            </td>
          </tr>
        </table>
      </li>
      <div style="clear: both"></div>
    </ul>
  </div>
</template>

<script type="text/babel">
export default {
  name: 'RouteListe',
  props: {
    routen: Array,
  },
  computed: {
    language: function () {
      return this.$root.$i18n.locale
    },
  },
}
</script>

<style scoped lang="scss">

</style>
