<template>
  <div id="app">
    <div>
      <div id="nav">
        <div class="inner">
          <router-link to="/">
            <img id="home-menu-btn" src="./assets/button-home.svg" title="Startseite" alt="Startseite">
          </router-link>
          <Backbutton />
          <span style="display: none">{{ $t('title') }}</span>

          <div v-if="loading">
            loading...
          </div>
          <span class="languages">
            <span style="margin-right: 10px" @click="changeLocale('de')">
              <img src="./assets/de.svg" title="Deutsch" alt="Deutsch">
            </span>
            <span style="margin-right: 10px;" @click="changeLocale('pl')">
              <img src="./assets/pl.svg" title="Polish" alt="Polish">
            </span>
            <span style="margin-right: 10px;" @click="changeLocale('cz')">
              <img src="./assets/cz.svg" title="Czech" alt="Czech">
            </span>
          </span>
        </div>
      </div>
      <transition :name="transitionName">
        <router-view />
      </transition>
    </div>

    <div class="pre-footer">
      <div class="row1">
        <div><a href="https://www.stiftung-umgebindehaus.de/Hinweise.html" target="_blank"><img src="./assets/button-Spenden.svg" /></a></div>
        <div><a href="https://www.stiftung-umgebindehaus.de/Deutscher-Fachwerktag.html" target="_blank"><img src="./assets/button-Fachwerktag.svg" /></a></div>
        <div><a href="https://www.stiftung-umgebindehaus.de/Immobilienboerse.html" target="_blank"><img src="./assets/button-Immobilien.svg" /></a></div>
      </div>
      <div class="row2">
        <div><a href="https://www.stiftung-umgebindehaus.de/" target="_blank"><img src="./assets/footer-lg-stiftung.svg" /></a></div>
        <div><a href="https://www.deutsche-fachwerkstrasse.de/" target="_blank"><img src="./assets/lg-Deutsche-Fachwerkstrasse.jpg" /></a></div>
        <div><a href="http://www.umgebindeland.de/" target="_blank"><img src="./assets/umgebindeLand.svg" /></a></div>
      </div>
    </div>

    <footer>
      <router-link to="/ds">Datenschutz</router-link> |
      <router-link to="/about">Impressum</router-link>
    </footer>
  </div>
</template>
<script type="text/babel">

import Backbutton from './components/Backbutton'

export default {
  name: 'Home',
  components: {  Backbutton},
  data: function () {
    return {
      transitionName: 'fade',
    }
  },
  computed: {
    loading() {
      return this.$root.loading
    },
  },
  watch: {
    '$route'(to, from) {
      let transitionName = ''
      const toDepth = to.path.split('/').length
      const fromDepth = from.path.split('/').length
      transitionName = toDepth < fromDepth ? 'slide-right' : 'slide-left'
      this.transitionName = transitionName || 'fade'
      //console.log(this.transitionName)
    },
  },
  methods: {
    changeLocale(locale) {
      this.$i18n.locale = locale
      // i18n.locale = locale;
    },
  },
}
</script>

<style lang="scss">
  @import "./assets/default.scss";

</style>
<style lang="scss" scoped>
  #nav {
    padding: 10px 20px;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    background-color: #1a1a1a;
    backdrop-filter: blur(5px);
    z-index: 1002;

    @media screen and (max-width: 480px) {
      padding: 0px 20px;
    }

    .inner {
      max-width: 1024px;
      margin: 0 auto;
    }
  }

  .languages img {
    width: 50px;
    cursor: pointer;

    @media screen and (max-width: 480px) {
      width: 30px;
      margin-top: 6px;
    }
  }

  #home-menu-btn {
    max-width: 50px;
    vertical-align: bottom;
    margin-top: 6px;

    @media screen and (max-width: 480px) {
      max-width: 30px;
      vertical-align: baseline;
    }
  }

  .slide-left-enter-active,
  .slide-left-leave-active,
  .slide-right-enter-active,
  .slide-right-leave-active {
    transition-duration: 0.5s;
    transition-property: height, opacity, transform;
    transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
    overflow: hidden;
  }

  .slide-left-enter,
  .slide-right-leave-active {
    opacity: 0;
    transform: translate(6em, 0);
  }

  .slide-left-leave-active,
  .slide-right-enter {
    opacity: 0;
    transform: translate(-6em, 0);
  }

  button {
    padding: 15px;
    border: 1px solid green;
    font-size: 18px;
    margin: 15px;
  }
</style>
