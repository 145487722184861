<template>
  <div class="header">
    <div class="header-container" :style="{ backgroundImage: 'url(' + AbsImagePath + ')' }">
      <div class="overlay-logo-container" :class="{mini: small}">
        <div class="overlay-logo">
          <a href=""><img src="../assets/lg-webapp-stiftung.svg"></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/babel">

export default {
  name: 'HomeHeader',
  props: {
    ImagePath: {
      type: String,
      default: '',
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    language: function () {
      return this.$root.$i18n.locale
    },

    AbsImagePath: function () {

      //console.log(process.env.apiPath);
      //console.log(process.env.BASE_URL);
      if (this.ImagePath && this.ImagePath.length)
        if (window.innerWidth > 800)
          return process.env.VUE_APP_API_ENDPOINT + 'fileman/imgsc/cropto/1600x800/' + this.ImagePath
        else
          return process.env.VUE_APP_API_ENDPOINT + 'fileman/imgsc/cropto/800x400/' + this.ImagePath
      else {
        if (window.innerWidth > 800)
          return process.env.VUE_APP_API_ENDPOINT + 'fileman/imgsc/cropto/1600x800/startseite/titel.jpg'
        else
          return process.env.VUE_APP_API_ENDPOINT + 'fileman/imgsc/cropto/800x400/startseite/titel.jpg'
      }
    },
  },

}
</script>

<style scoped lang="scss">
  .header-container {
    padding-bottom: 40%;
    box-sizing: border-box;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    position: relative;

    @media screen and (max-width: 480px) {
      padding-bottom: 65%;
    }

    .overlay-logo-container {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      line-height: 0;
      border-bottom: 5px solid #1a1a1a;
      box-sizing: border-box;

      &.mini {
        .overlay-logo {
          img {
            max-width: 160px;

            @media screen and (max-width: 768px) {
              max-width: 140px;
            }
            @media screen and (max-width: 480px) {
              max-width: 120px;
            }
          }
        }
      }

      .overlay-logo {
        max-width: 1024px;
        margin: 0 auto;
        margin-bottom: -6px;

        @media screen and (max-width: 1024px) {
          padding-left: 30px;
          box-sizing: border-box;
        }

        img {
          max-width: 200px;

          @media screen and (max-width: 768px) {
            max-width: 140px;
          }
          @media screen and (max-width: 480px) {
            max-width: 160px;
          }
        }
      }
    }
  }
</style>
