import { render, staticRenderFns } from "./Backbutton.vue?vue&type=template&id=17a39bc9&scoped=true&"
import script from "./Backbutton.vue?vue&type=script&lang=js&"
export * from "./Backbutton.vue?vue&type=script&lang=js&"
import style0 from "./Backbutton.vue?vue&type=style&index=0&id=17a39bc9&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../.pnp/externals/pnp-12b91bfd89d82152724093e32982d62081f84c72/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "17a39bc9",
  null
  
)

export default component.exports