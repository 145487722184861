import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'

import i18n from './i18n.js'

import './registerServiceWorker'

//Vue.config.productionTip = false

import {Icon} from 'leaflet'
//import 'leaflet/dist/leaflet.css'


// this part resolve an issue where the markers would not appear
delete Icon.Default.prototype._getIconUrl

Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
})

var vm = new Vue({
  data: {
    loading: false,
    BPRoutes: [],
    BPPoints: [],
    content: {},
    solvedQuizes: [],
    currentlocation: {lat: null, lon: null},
  },
  watch: {
    solvedQuizes: {
      handler() {
        //console.log('Quizdata changed!');
        localStorage.setItem('solvedQuizes', JSON.stringify(this.solvedQuizes))
      },
      deep: true,
    },
  },
  mounted() {
    let self = this

    if (navigator.geolocation) {
      var id, target, options

      options = {
        enableHighAccuracy: false,
        timeout: 5000,
        maximumAge: 0,
      }

      id = navigator.geolocation.watchPosition(function (position) {
        console.log(position.coords.latitude, position.coords.longitude)
        self.currentlocation.lat = position.coords.latitude
        self.currentlocation.lon = position.coords.longitude
      },
      function (err) {
        console.warn('ERROR(' + err.code + '): ' + err.message)
        if(err.code == 1) {
          // alert("Error: Access is denied!");
        } else if( err.code == 2) {
        //  alert("Error: Position is unavailable!");
        }
      }, options)

      /*
      navigator.geolocation.getCurrentPosition(function (position) {
        console.log(position.coords.latitude, position.coords.longitude)
        self.currentlocation.lat = position.coords.latitude
        self.currentlocation.lon = position.coords.longitude
      })*/
    }

    this.loading = true
    axios
      .get(process.env.VUE_APP_API_ENDPOINT + 'routen/list')
      .then(response => {
        //console.log(response.data);
        this.BPRoutes = response.data
      })
      .catch(error => {
        //console.log(error)
        this.errored = true
      })
      .finally(() => this.loading = false)
    axios
      .get(process.env.VUE_APP_API_ENDPOINT + 'punkte/list')
      .then(response => {
        //console.log(response.data);
        this.BPPoints = response.data
      })
      .catch(error => {
        //console.log(error)
        this.errored = true
      })
      .finally(() => this.loading = false)
    axios
      .get(process.env.VUE_APP_API_ENDPOINT + 'routen/contentapi')
      .then(response => {
        //console.log(response.data);
        this.content = response.data
      })
      .catch(error => {
        //console.log(error)
        this.errored = true
      })
      .finally(() => this.loading = false)
  },
  i18n,
  router,
  render: h => h(App),
}).$mount('#app')
