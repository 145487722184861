<template>
  <span>
    <img v-if="currentrouteNotHome===true"
         id="trenner" src="./../assets/linie-vertikal.svg" title="Trenner" alt="Trenner" style="max-width: 1px" />
    <img v-if="currentrouteNotHome===true"
         src="./../assets/picto-pfeil-back.svg" title="zurück" alt="zurück"
         id="backbutton" @click="goBack"
    />
  </span>
</template>
<script type="text/babel">
export default {
  name: 'Backbutton',
  data() {
    return {
      currentroute: null,

    }
  },
  computed: {

    currentrouteNotHome() {
      //  console.log(this.currentroute)
      return this.currentroute !== 'home' && this.currentroute != null

    },
  },
  mounted() {

    this.$router.afterEach((to, from, next) => {

      //    console.info('App currentRoute:', this.$router.currentRoute)

      this.currentroute = this.$router.currentRoute.name

      // next()
    })


  },


  methods: {
    goBack() {

      window.history.length > 1
        ? this.$router.go(-1)
        : this.$router.push('/')
    },
  },
}
</script>

<style lang="scss" scoped>

  #backbutton {

    -webkit-appearance: none;
    border: none;

    padding-top: 10px;
    padding-bottom: 0px;
    margin-bottom: 8px;

    padding-left: 20px;
    padding-right: 10px;

    max-width: 13px;
    vertical-align: bottom;
    cursor: pointer;


    @media screen and (max-width: 480px) {
      max-width: 9px;
      padding-left: 10px;
    }
  }

  #trenner {
    padding-left: 20px;

    @media screen and (max-width: 480px) {
      padding-left: 10px;
      height: 23px;
    }
  }

</style>
