<template>
  <div class="video">
    <h2 v-if="title" :style="{color:titlecolor}">{{ title }}</h2>

    <div class='embed-container' v-if="visible">
      <iframe :src='videourl' frameborder='0' allowfullscreen></iframe>
    </div>
    <div class='embed-container ' v-if="!visible" @click="visible=true">
      <div class="Placeholder" :style="{backgroundImage:'url(' +placeholderourl+ ')'}"></div>
      <div class="showVideoContent">
        Bitte anklicken, um den externen Videoinhalt von Youtube zu laden. Damit stimmen Sie den Nutzungsbedingungen
        von Youtube zu.
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'Video',

  props: {
    title: String,
    videoid: String,
    titlecolor: String,
  },
  computed: {
    videourl: function () {
      return '//www.youtube-nocookie.com/embed/' + this.videoid + '?rel=0&amp;showinfo=0'
    },
  },
  data() {
    return {
      visible: false,
      placeholderourl: 'https://www.umgebinde.haus/fileman/imgsc/cropto/1600x800/startseite/titel.jpg'
    }
  }
}
</script>

<style scoped lang="scss">
h2 {
  text-align: center;
}

.video {
  max-width: 1024px;
  margin: 0 auto;
  padding-bottom: 80px;
  box-sizing: border-box;

  @media screen and (max-width: 480px) {
    padding-bottom: 40px;
  }
}

div.video .embed-container {
  margin-bottom: 10px;
}

.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  overflow: hidden;
  max-width: 100%;
  height: auto;
}

.embed-container iframe, .embed-container object, .embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.embed-container .showVideoContent {

  border: 0;
  position: absolute;
  box-sizing: border-box;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  margin: 0;
  background: rgba(0, 0, 0, 0.75);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.embed-container .Placeholder {

  border: 0;
  position: absolute;
  box-sizing: border-box;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  margin: 0;

  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;

  pointer-events: none;
  padding: 20px;
}
</style>
